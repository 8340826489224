import React, { useState, useEffect } from 'react'
import "./generate.css"
import { FormControl, InputLabel, MenuItem, Select, TextField, Autocomplete } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {LocalizationProvider} from "@mui/x-date-pickers"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getEmployees, generateCompanyReport, generateEmployeeReport, getLocations, generateLocationReport } from '../../Api';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function GenerateReport() {
    const navigate = useNavigate()
    const [reportType, setReportType] = React.useState('Company Report');
    const [showDownload, setShowDownload] = React.useState("");
    const [userInfo, setUserInfo] = useState(null);
    const [content, setContent] = useState({ employee: null, location: null });
    const [value, setValue] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleChange = (event) => {
        setReportType(event.target.value);
        setShowDownload("");
    };

    const generateReport = async () => {
        setShowDownload("");
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let data = {};
        data.account_code = userData.account_code;
        if(reportType === "Employee Report"){
            data.emp_code = value.emp_code ? value.emp_code : "";
            if(!data.emp_code){
                Swal.fire({
                    text: 'Select employee',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                });
                return;
            }
        }
        if(startDate){
            data.start_date = startDate;
        }else{
            Swal.fire({
                text: 'Select start date',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            });
            return;
        }
        if(endDate){
            data.end_date = endDate;
        }else{
            Swal.fire({
                text: 'Select end date',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            });
            return;
        }
        
        if(reportType === "Company Report"){
            let apiData = await generateCompanyReport(data);
            if(apiData.status === "S"){
                if(apiData.result_info){
                    setShowDownload(apiData.result_info)
                }
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
        if(reportType === "Employee Report"){
            let apiData = await generateEmployeeReport(data);
            if(apiData.status === "S"){
                if(apiData.result_info){
                    setShowDownload(apiData.result_info)
                }
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
       
        if(reportType === "Location Report"){
            if(userInfo && userInfo.account_type === 'ams'){
                data.location_code = value.location_code;
            }
            if(userInfo && userInfo.account_type === 'ams' && !data.location_code){
                Swal.fire({
                    text: 'Select Location',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                });
                return;
            }
            let apiData = await generateLocationReport(data);
            if(apiData.status === "S"){
                if(apiData.result_info){
                    setShowDownload(apiData.result_info)
                }
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }

    }

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            setUserInfo(userData);
            let apiData = await getEmployees(userData.account_code);
            let locationData = await getLocations(userData.account_code);
            if(apiData.status === "S"){
                setContent({employee: [...apiData.result_info], locations: [...locationData.result_info]})
            }
        })();
    }, [])

    useEffect(() => {
        setValue({});
    }, [reportType])

  return (
    <div className='generateContainer'>
        <h5 className='generate-report'>Generate Report</h5>
        <div className="card forms-card">
            <div className="row">
                <div className="col-12 mb-4">
                    <FormControl fullWidth>
                        <InputLabel className='w-100' id="demo-simple-select-label">Report Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={reportType}
                            label="Report Type"
                            onChange={handleChange}
                        >
                            <MenuItem value={"Company Report"}>Company Report</MenuItem>
                            <MenuItem value={"Employee Report"}>Employee Report</MenuItem>
                            <MenuItem value={"Location Report"}>Location Report</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
           {reportType === "Employee Report" && <div className="row">
                <div className="col-12 mb-4">
                    <div className='w-100'>
                        {content && content.employee && <Autocomplete className='w-100'
                        disablePortal
                        onInputChange={(event, inputVal) => {
                            if(!inputVal){
                                setValue({});
                            }
                        }}
                        onChange={(event, newValue) => {
                            if(!newValue){
                                setValue({});
                            }else{
                                setValue(newValue);
                            }
                        }}
                        id="combo-box-demo"
                        options={[...content.employee]}
                        getOptionLabel={(option) => option.emp_name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Employee" />}
                        />}
                    </div>
                </div>
            </div>}
           {userInfo && userInfo.account_type === 'ams' && reportType === "Location Report" && <div className="row">
                <div className="col-12 mb-4">
                    <div className='w-100'>
                        {content && content.locations && content.locations.length > 0 && <Autocomplete className='w-100'
                        disablePortal
                        onInputChange={(event, inputVal) => {
                            if(!inputVal){
                                setValue({});
                            }
                        }}
                        onChange={(event, newValue) => {
                            if(!newValue){
                                setValue({});
                            }else{
                                setValue(newValue);
                            }
                        }}
                        id="combo-box-demo"
                        options={[...content.locations]}
                        getOptionLabel={(option) => option.location_name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Location" />}
                        />}
                    </div>
                </div>
            </div>}
            <div className="row">
                <div className="col-12 col-lg-6 mb-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className='w-100' label=" Start Date" 
                            onChange={(ev) => {
                                let date = ev?.$d;
                                setStartDate(dayjs(date).format('YYYY-MM-DD'));
                                setEndDate("");
                            }}
                        />
                    </LocalizationProvider>
                </div>
                <div className="col-12 col-lg-6 mb-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker disabled={startDate === ''} minDate={dayjs(startDate)} value={dayjs(endDate)} className='w-100' label=" End Date" 
                            onChange={(ev) => {
                                let date = ev?.$d;
                                setEndDate(dayjs(date).format('YYYY-MM-DD'))
                            }}
                        />
                    </LocalizationProvider>
                </div>
            </div>
            <div className="text-center">
               <button className='btn btn-dark' onClick={() => {generateReport()}}>Submit</button>
            </div>
        </div>
        {showDownload && <div className="card table-card mt-4">
            <table className='table'>
                <thead>
                    <tr>
                    <th scope="col">Filename</th>
                    <th scope="col">Download</th>
                    </tr>
                </thead>
                <tbody className='text-start'>
                    <tr>
                        <td>{reportType}</td>
                        <td>
                            <a href={'https://attendlog.com/api/files'+showDownload} target='_blank' rel="noreferrer"><DownloadIcon /></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>}
    </div>
  )
}

export default GenerateReport
