import React, { useEffect, useState } from 'react'
// import "./manage.css"
import { TextField } from '@mui/material'
// import Table from '../../components/table/Table'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { deleteMember, getMembers } from '../../../../Api';

function ManageMembers({navigation}) {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [empData, setEmpData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 75;
    const [totalPages, setTotalPages] = useState(null);

    const filteredData = [...empData].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
      setSearchQuery(event.target.value);
      setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            let newPage = currentPage;
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            let newPage = currentPage;
            setCurrentPage(currentPage - 1);
        }
    };

    const editPage = (member_id) => {
        navigate('/bni/edit-member/'+member_id);
    }

    const deleteMem = async (id) => {
        Swal.fire({
          text:  `Are you sure you want to delete the member?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(async (res) => {
          if(res.isConfirmed){
            let apiRes = await deleteMember(id);
            console.log(apiRes);
            if(apiRes.status === "S"){
              Swal.fire({
                text:  `Employee Deleted`,
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
              }).then(() => {
                fetchData();
              });
            }
          }
        }) 
      }

    const fetchData = async () => {
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'}
        });
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let acc_code = userData.account_code;
        let apiData = await getMembers(acc_code);
        apiData.status === "S" ?  setEmpData(apiData.result_info) : setEmpData([]);
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'},
            timer: 10
        });
        apiData.status === "S" ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)): setTotalPages(0);
        if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
            return Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
        if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
            return Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
    }

    useEffect(() => {
        (async () => {
            Swal.fire({
                html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
                showConfirmButton: false,
                heightAuto: false,
                customClass: {popup: 'loader-swal'}
            });
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let acc_code = userData.account_code;
            let apiData = await getMembers(acc_code);
            apiData.status === "S" ?  setEmpData(apiData.result_info) : setEmpData([]);
            Swal.fire({
                html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
                showConfirmButton: false,
                heightAuto: false,
                customClass: {popup: 'loader-swal'},
                timer: 10
            });
            apiData.status === "S" ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)) : setTotalPages(0);
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                return Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                return Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        })();
    }, [])

    
    return (
        <div className='manageContainer'>
        <h5 className='manage-employee'>Manage Members</h5>
            <div className='searchbar-div'>
                <div className='searchbar'>
                    <TextField id="standard-basic" label="Search" variant="standard" value={searchQuery} onChange={handleSearchChange} />
                </div>
                <Link to="/bni/create-member">
                    <div className="buttonCreate">
                        <button className='btn btn-dark'>+ Create</button>
                    </div>
                </Link>
            </div>
            <div className="card table-card mt-4">
                <div className="tableContainer emp-table">
                    <table className={paginatedData.length > 0 ? "table" : "table mb-0"}>
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Business Name</th>
                            <th style={{width: '20%'}}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            {paginatedData && (paginatedData.length > 0) && paginatedData.map((row) => (
                                <tr key={'mem'+row.member_id}>
                                    <td>{row.name}</td>
                                    <td>{row.mobile}</td>
                                    <td>{row.business_name}</td>
                                    <td style={{width: '20%'}}>
                                    <button className="btn btn-outline-primary px-4 me-2" onClick={() => {
                                        // localStorage.setItem('member_id', row.id)
                                        editPage(row.member_id);
                                    }}>Edit</button>
                                    <button className="btn btn-outline-danger px-3" onClick={() => {deleteMem(row.member_id)}}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> 
                    {paginatedData && (paginatedData.length === 0) && <div className='p-2 bb2 w-100 mb-2'>
                        No Records
                    </div>}
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                            <i className="fa-solid fa-chevron-left"></i>
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                            <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
                {/* <Table rows={empData} headerCells={headerCells} update={setEmpData} deleteRow={deleteEmployee} editRoute={editPage} /> */}
            </div>
        </div>
    )
}

export default ManageMembers
