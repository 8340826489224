import React, { useEffect, useRef, useState } from 'react';
import './capturelogin.css';
import { useParams } from 'react-router-dom';
import { getEmployeesData, captureAttendance } from '../../Api';
import { compressImg } from '../../ImageCompressor';
import Swal from 'sweetalert2';
import {TextField} from '@mui/material';

function CaptureCMSLogin() {
  const [isThank, setIsThank] = useState(null);
  const {location_code, account_code} = useParams();
  const fileIp = useRef();
  const [accInfo, setAccInfo] = useState({account_type: '', account_code: '', account_name: '', location_name: '', account_logo: '', latitude: null, longitude: null, employees: []});
  const [value, setValue] = useState({});
  const [img, setImg] = useState('');
  const [imgFile, setImgFile] = useState(null);

  const handleImage = (event) => {
    setImg('');
    const file = event.target.files[0];
    console.log('Before compression', file.size)
    let splitfile = file.name;
    let extension = splitfile.split('.').pop();
    if(extension === 'png' || extension === 'PNG' || extension === 'jpg' || extension === 'JPG' || extension === 'jpeg' || extension === 'JPEG') {
        console.log('valid file');
        var reader = new FileReader();
        if(event.target.files[0]){
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (e)=>{
                setImg(e.target.result);
            }
            compressImg(event.target.files[0]).then(img => {
                setImgFile(img);
                console.log('After compression', img.size)
            });
        }
    }else{
        Swal.fire({
            text: 'Invalid file format. Only .png, .jpg files are allowed',
            icon: 'warning',
            heightAuto: false
        })
        return
    }
  }

  const submit = async () => {
    if(!imgFile){
      Swal.fire({
        text: 'Please take selfie',
        icon: 'warning',
        heightAuto: false,
        showConfirmButton: false,
        timer: 3000
      })
    }else {
      let formData =  new FormData();
      formData.append("account_code", accInfo.account_code);
      formData.append("account_name", accInfo.account_name);
      formData.append("location_code", location_code);
      formData.append("location_name", accInfo.location_name);
      formData.append("emp_name", value.emp_name);
      formData.append("emp_code", value.emp_code);
      formData.append("latitude", '0.000000');
      formData.append("longitude", '0.000000');
      if(imgFile){
        formData.append("selfie", imgFile);
      }
      let apiData;
      apiData = await captureAttendance(formData);
      if(apiData.status === "S"){
        Swal.fire({
          text: 'Success',
          icon: 'success',
          heightAuto: false,
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
            setIsThank(true);
        })
      }else{
        Swal.fire({
          text: JSON.stringify(apiData),
          icon: 'error',
          heightAuto: false,
          showConfirmButton: false,
          timer: 3000
        })
      } 
    }
  }

  useEffect(() => {
    (async () => {
      let apiData = await getEmployeesData(account_code, location_code);
      if(apiData.status === "S"){
          setAccInfo({
            account_type: apiData.account_type, 
            account_code: apiData.account_code, 
            account_name: apiData.account_name, 
            location_name: apiData.location_name, 
            account_logo: apiData.account_logo,
            latitude: +apiData.location_latitude,
            longitude: +apiData.location_longitude,
            employees: [...apiData.employee_info]
          })
      }else if(apiData.status === "E"){
        Swal.fire({
          text: apiData.message,
          icon: 'warning',
          heightAuto: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          allowEscapeKey: false
        })
      }
    })()
  }, [account_code, location_code]);

  return (<> 
    {accInfo.account_name && accInfo.account_type === 'cms' && <div className="checkin-page">
      <div className="header">
        <div className="admin-name">
          { !isThank && imgFile ? 'Employee Info': accInfo.account_name}
        </div>
      </div>
      {!isThank && <div className="divider"></div>}
      { !isThank && !imgFile &&
        <div className="body1">
          <img src={accInfo.account_logo ? 'https://attendlog.com/api/files/account_logos/'+accInfo.account_logo : 'https://placehold.co/250x70'} alt="" />
          <div className='mt-4 w-100'>
            <TextField className="mb-3 w-100" focused label="Employee Code" onChange={(ev) => {
                let val = ev.target.value.toLowerCase() 
                let employeeInfo = [...accInfo.employees].filter((emp) => emp.emp_code === val)
                setValue(employeeInfo[0]);
              }}
            />
          </div>
          <input type="file" 
            hidden 
            ref={fileIp}
            onChange={(ev) => {
              handleImage(ev)
            }}
            id="selfie" 
            name="selfie"
            accept="image/*" 
            capture="user"
          />
          <button className="btn btn-dark mt-3 rounded-pill px-4" disabled={!value?.emp_code} id="btnIp" onClick={() => {
            fileIp.current.click();
          }}>Mark Attendance</button>
        </div>
      }
      { !isThank && imgFile && 
        <div className="body2">
        <img src={img ? img : 'https://placehold.co/100x150'} alt="" />
        <div className='mt-4'>
          <TextField className="mb-3 w-100" focused label="Employee Code" disabled value={value.emp_code} />
          <TextField className="mb-3 w-100" focused label="Employee Name" disabled value={value.emp_name} />
          {/* <TextField className="mb-3 w-100" focused label="Mobile Number" disabled value={value.phone} /> */}
          {/* <TextField className="mb-3 w-100" focused label="Designation" disabled value={value.designation} /> */}
        </div>
        {accInfo.account_type === 'cms' && <button className="btn btn-dark mt-3 rounded-pill w-100" id="btnIp" onClick={() => {submit()}}>Check In</button>}
      </div>
      }
      { isThank &&
        <div className="body">
          <img src={accInfo.account_logo ? 'https://attendlog.com/api/files/account_logos/'+accInfo.account_logo : 'https://placehold.co/250x70'} alt="" />
          <div className='w-100 text-center'>
            <i className="fa-regular fa-check-circle text-success"></i>
            <p className="mt-3 mb-4">SUCCESS</p>
          </div>
          <p>powered by <span className='text-warning'>AttendLog</span></p>
        </div>
      }
    </div>}
  </>)
}

export default CaptureCMSLogin