import React from 'react'
import { TextField } from '@mui/material';
import { createLoc } from '../../../../Api';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function CreateBNILocation() {
    const navigate = useNavigate();
    const [name, setName] = React.useState('');

    const submit = async () => {
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'}
        });
        if(!name){
            Swal.fire({
                text: 'Please provide name',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
        }else{
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let data = {
                location_name: name,
                location_code: userData.account_code,
                latitude : '0.0',
                longitude : '0.0',
                url: 'https://attendlog.com/bni/'+userData.account_code+'/'+name,
            };
            data.account_code = userData.account_code;
            data.account_name = userData.business_name;
            let apiData = await createLoc({...data});
            Swal.fire({
                html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
                showConfirmButton: false,
                heightAuto: false,
                customClass: {popup: 'loader-swal'},
                timer: 10
            });
            console.log(apiData);
            if(apiData.status === "S"){
                Swal.fire({
                    text: 'QR created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/bni/manage-qr')
                })
            }
            if(apiData.result_code === 201){
                Swal.fire({
                    text: 'Limit Exceeded',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                }).then(() => {
                    navigate('/bni/manage-qr')
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }

    return (
        <div className='locationContainer'>
            <h5 className='create-location'>Create QR</h5>
            <div className="card forms-card">
                <div className="row">
                    <div className="col-12 mb-3">
                        <TextField className='w-100' value={name} id="outlined-basic" label="Name" 
                            onChange={(ev) => {
                                let regex = /[^a-zA-Z0-9_-]/gi;
                                setName(ev.target.value.replace(regex, ""))
                            }} 
                            variant="outlined"
                            autoComplete="off" 
                            required 
                        />
                    </div>
                </div>
                <div className="text-center mt-4">
                    <button className='btn btn-dark me-4' onClick={() => {submit()}}>Create</button>
                    <button className='btn btn-danger' onClick={() => {navigate('/bni/manage-qr')}}>Cancel</button>
                </div>

            </div>
        
        </div>
    )
}

export default CreateBNILocation
