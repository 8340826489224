import React, {useState, useEffect} from 'react'
// import "./create.css"
import { FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { updateEmp, getEmp, getLocations } from '../../Api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Editemployee() {
    const navigate = useNavigate();
    const [locations, setLocations] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [empObj, setEmpObj] = useState({
        emp_code: "",
        emp_name: "",
        email: "",
        phone: "",
        designation: "",
        dob: "",
        location_code: "",
        address: "",
        city: "",
        state: "",
        country: "",
    })

    const fetchData = async () => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let account_code = userData.account_code;
        setUserInfo(userData);
        let emp_code = localStorage.getItem('emp_code');
        let apiData = await getEmp(account_code, emp_code);
        if(apiData.status === "S"){
            setEmpObj(apiData.result_info)
        }else{
            setEmpObj({
                emp_code: "",
                emp_name: "",
                email: "",
                phone: "",
                designation: "",
                dob: "",
                location_code: "",
                address: "",
                city: "",
                state: "",
                country: "",
            })
        }
        let locationData = await getLocations(account_code); 
        if(locationData.status === "S"){
            setLocations([...locationData.result_info])
        }
    }
    const updateEmployee = async () => {
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if(empObj.email && !emalval.test(empObj.email)){
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.phone){
            Swal.fire({
                text: 'Please provide Mobile Number!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.designation){
            Swal.fire({
                text: 'Please provide Designation!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else{
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let apiData = await updateEmp({
                account_code: userData.account_code,
                emp_code: empObj.emp_code,
                email: empObj.email.toLowerCase(),
                phone: empObj.phone,
                designation: empObj.designation,
                dob: empObj.dob,
                location_code: empObj.location_code,
                address: empObj.address,
                city: empObj.city,
                state: empObj.state,
                country: empObj.country,
            });
            // console.log(apiData);
            if(apiData.status === "S"){
                Swal.fire({
                    text: 'Employee updated successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-employees')
                })
            }else if(apiData.status === "E" && apiData.result_code === 404){
                Swal.fire({
                    text: 'Employee not found',
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-employees')
                })
            }else if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }else if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }else{
                Swal.fire({
                    text: JSON.stringify(apiData),
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className='employeeContainer'>
            <h5 className='create-employee'>Edit Employee</h5>
            {empObj.emp_name && <div className="card forms-card">
                <div className="row mb-3">
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Employee Name" disabled value={empObj.emp_name || ''} variant="outlined" autoComplete="off" required />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Employee Code" disabled value={empObj.emp_code || ''} variant="outlined" autoComplete="off" required />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Email" value={empObj.email || ''} onChange={(ev) => {
                            setEmpObj({...empObj, email: ev.target.value})
                        }} variant="outlined" autoComplete="off" />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Mobile Number" value={empObj.phone || ''} variant="outlined" autoComplete="off" required
                            onChange={(ev) => {
                                setEmpObj({...empObj, phone: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Designation" value={empObj.designation || ''} variant="outlined" autoComplete="off" required
                            onChange={(ev) => {
                                setEmpObj({...empObj, designation: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker className='w-100' label="Date of Birth"
                                defaultValue={empObj.dob ? dayjs(empObj.dob) : null}
                                value={empObj.dob ? dayjs(empObj.dob) : null}
                                onChange={(ev) => {
                                    let date = ev?.$d;
                                    setEmpObj({...empObj, dob: dayjs(date).format('YYYY-MM-DD')})
                                }} 
                            />
                        </LocalizationProvider>
                    </div>
                    {userInfo && userInfo.account_type === 'ams' && <div className="col-12 col-lg-6 mb-3">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Location</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={empObj.location_code}
                                label="Select Location"
                                onChange={(ev) => {
                                    setEmpObj({...empObj, location_code: ev.target.value})
                                }}
                                disabled
                            >
                               {locations && locations.map((location) => <MenuItem key={'location-'+location.id} value={location.location_code}>{location.location_name} </MenuItem>)}
                                
                            </Select>
                        </FormControl>
                    </div>}
                    {/* <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="City" value={empObj.city || ''} variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, city: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="State" value={empObj.state || ''} variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, state: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Country" value={empObj.country || ''} variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, country: ev.target.value})
                            }}
                        />
                    </div> */}
                    <div className="col-12 mb-3">
                        <TextField className='w-100' multiline rows={3} id="outlined-basic" label="Address" value={empObj.address || ''} variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, address: ev.target.value})
                            }}
                        />
                    </div>
                </div>
                <div className="row buttonsRow">
                <button className='createButton me-4' onClick={() => {updateEmployee()}}>Update</button>
                <button className='cancelButton' onClick={() => {navigate('/app/manage-employees')}}>Cancel</button>
                </div>
            </div>}
        </div>
    )
}

export default Editemployee
