import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, TextField, Modal } from '@mui/material'
import { getBNIMembersLog } from '../../../Api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  textAlign: 'center',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

function BNIAttendanceLog() {
    const navigate = useNavigate();
    const [type, setType] = useState('member');
    const [searchQuery, setSearchQuery] = useState('');
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 75;
    const [totalPages, setTotalPages] = useState(0);

    
    const [modalImg, setModalImg] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const handleOpen = (url) => {
        setOpen(true);
        setModalImg(url)
    };
    const handleClose = () => setOpen(false);

    const filteredData = [...tableData].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
      setSearchQuery(event.target.value);
      setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        (async () => {
          if(type){
            Swal.fire({
              html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
              showConfirmButton: false,
              heightAuto: false,
              customClass: {popup: 'loader-swal'}
            });
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let acc_code = userData.account_code;
            let apiData = await getBNIMembersLog(acc_code);
            let reportType = type+'s';
            apiData.status === "S" ?  setTableData(apiData[reportType]) : setTableData([]);
            Swal.fire({
              html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
              showConfirmButton: false,
              heightAuto: false,
              customClass: {popup: 'loader-swal'},
              timer: 10
            });
            apiData.status === "S" ?  setTotalPages(Math.ceil([...apiData[reportType]].length / pageSize)) : setTotalPages(0);
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
          }
        })();
    }, [type, navigate])


    
    return (
        <div className='manageContainer'>
        <h5 className='manage-employee'>Attendance Log</h5>
            {/* <div className='searchbar-div mb-4'>
                <div className='searchbar'>
                    <TextField id="standard-basic" label="Search" variant="standard" value={searchQuery} onChange={handleSearchChange} />
                </div>
            </div> */}
            <div className="card table-card mt-4">
              <div className="row mb-3">
                <div className="col-12 mt-3">
                  <FormControl fullWidth>
                    <InputLabel className="w-100" id="demo-simple-select-label">
                      Select Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={type}
                      label="Select Type"
                      onChange={(ev) => {setType(ev.target.value)}}
                    >
                      <MenuItem value={"member"}>Members</MenuItem>
                      <MenuItem value={"substitute"}>Substitutes</MenuItem>
                      <MenuItem value={"visitor"}>Visitors</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
                <div className="tableContainer location-table">
                    <table className={paginatedData.length > 0 ? "table" : "table mb-0"}>
                        <thead>
                        <tr>
                            <th scope="col">Date</th>
                            {type === 'member' && <>
                              <th scope="col">Member Name</th>
                              <th scope="col">Time</th>
                              <th scope="col">Status</th>
                              <th scope="col">Image</th>
                            </>}
                            {type === 'substitute' && <>
                              <th scope="col">Member Name</th>
                              <th scope="col">Substitute Name</th>
                              <th scope="col">Time</th>
                              <th scope="col">Image</th>
                            </>}
                            {type === 'visitor' && <>
                              <th scope="col">Visitor Name</th>
                              <th scope="col">Mobile</th>
                              <th scope="col">Invited By</th>
                            </>}
                            {/* <th scope="col">Actions</th> */}
                        </tr>
                        </thead>
                        <tbody>
                            {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.date}</td>
                                    {type === 'member' && <>
                                      <td>{row.member_name}</td>
                                      <td>{row.checkin_time}</td>
                                      <td>{row.status}</td>
                                      <td><img  onClick={() => {
                                                handleOpen('https://attendlog.com/api/files/bni_attendance_images/'+row.checkin_image)}
                                            }  style={{height: 50}} src={'https://attendlog.com/api/files/bni_attendance_images/'+row.checkin_image} alt="" /></td>
                                    </>}
                                    {type === 'substitute' && <>
                                      <td>{row.member_name}</td>
                                      <td>{row.substitute_name}</td>
                                      <td>{row.checkin_time}</td>
                                      <td>
                                        <img  
                                            onClick={() => {
                                              handleOpen('https://attendlog.com/api/files/bni_attendance_images/'+row.checkin_image)}
                                            }  
                                            style={{height: 50}} src={'https://attendlog.com/api/files/bni_attendance_images/'+row.checkin_image} alt="" /></td>
                                    </>}
                                    {type === 'visitor' && <>
                                      <td>{row.visitor_name}</td>
                                      <td>{row.mobile}</td>
                                      <td>{row.invited_by}</td>
                                    </>}
                                    {/* <td>
                                        <button className="btn btn-outline-primary px-4" onClick={() => {
                                            localStorage.setItem("log_date", row.date);
                                            localStorage.setItem("emp_code", row.emp_code);
                                            navigate('/app/individuallog');
                                        }}>View</button>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table> 
                    {paginatedData && (paginatedData.length === 0) && <div className='p-2 bb2 w-100 mb-2'>
                        No Records
                    </div>}
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                            <i className="fa-solid fa-chevron-left"></i>
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                            <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            <Modal sx={style}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <img style={{height: '50vh'}} src={modalImg} alt="" />
            </Modal>
        </div>
    )
}

export default BNIAttendanceLog
