import React from 'react'
import "./sidebar.css"
import { MdFeedback, MdAddLocationAlt, MdCalendarToday, MdLock, MdOutlinePerson,  MdOutlineGroup,  MdOutlinePowerSettingsNew } from "react-icons/md";
import { Link, useLocation } from 'react-router-dom';
import { QrCode } from '@mui/icons-material';

function Sidebar({ stateVal, trigger, role, accountType }) {

  const location = useLocation();
  const { pathname } = location;

  const closeSideMenu = () => {
    if(window.innerWidth < 992){
      trigger()
    }
  }

  return (
    <div className={`sidebarContainer ${stateVal ? '' : 'd-none'}`}>
      {accountType !== 'bni' && <div className="sidebarContent">
        {+role === 1 && <Link to="/admin/manage-accounts" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/admin/manage-accounts' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdOutlineGroup className='icon' />
            <p className='actualText'>Accounts</p>
          </div>
        </Link>}
        {+role === 1 && <Link to="/admin/manage-employees" className="sidebar-link">
          <div className={pathname === '/admin/manage-employees' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdOutlineGroup className='icon' />
            <p className='actualText'>Employees</p>
          </div>
        </Link>}
        {+role === 2 && <Link to="/app/manage-employees" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/app/manage-employees' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdOutlineGroup className='icon' />
            <p className='actualText'>Employees</p>
          </div>
        </Link>}
        {+role === 2 && <Link to="/app/manage-locations" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/app/manage-locations' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdAddLocationAlt className='icon' />
            <p className='actualText'>Locations (QR)</p>
          </div>
        </Link>}
        {+role === 2 && <Link to="/app/attendencelog" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/app/attendencelog' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdCalendarToday className='icon' />
            <p className='actualText'>Attendance Log</p>
          </div>
        </Link>}
        {+role === 2 && <Link to="/app/generatereport" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/app/generatereport' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdFeedback className='icon' />
            <p className='actualText'>Reports</p>
          </div>
        </Link>}
        <Link to="/app/reset-password" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/app/reset-password' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdLock className='icon' />
            <p className='actualText'>Reset Password</p>
          </div>
        </Link>
        {+role === 2 && <Link to="/app/profile" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/app/profile' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdOutlinePerson className='icon' />
            <p className='actualText'>Profile</p>
          </div>
        </Link>}
          <Link to="/app/login" onClick={() => {
            closeSideMenu();
            localStorage.clear()
          }} className="sidebar-link">
            <div className="subContent mb-2">
              <MdOutlinePowerSettingsNew className='icon' />
              <p className='actualText'>Log Out</p>
            </div>
          </Link>
      </div>}
      {accountType === 'bni' && <div className="sidebarContent">
        <Link to="/bni/manage-members" className="sidebar-link">
          <div className={pathname === '/bni/manage-members' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdOutlineGroup className='icon' />
            <p className='actualText'>Members</p>
          </div>
        </Link>
        <Link to="/bni/manage-qr" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/bni/manage-qr' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <QrCode className='icon' />
            <p className='actualText'>QR</p>
          </div>
        </Link>
        <Link to="/bni/attendancelog" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/bni/attendancelog' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdCalendarToday className='icon' />
            <p className='actualText'>Attedance Log</p>
          </div>
        </Link>
        <Link to="/bni/generatereport" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/bni/generatereport' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdFeedback className='icon' />
            <p className='actualText'>Reports</p>
          </div>
        </Link>
        <Link to="/app/reset-password" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/app/reset-password' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdLock className='icon' />
            <p className='actualText'>Reset Password</p>
          </div>
        </Link>
        <Link to="/bni/profile" onClick={() => {closeSideMenu()}} className="sidebar-link">
          <div className={pathname === '/bni/profile' ? 'subContent mb-2 active' : 'subContent mb-2' }>
            <MdOutlinePerson className='icon' />
            <p className='actualText'>Profile</p>
          </div>
        </Link>
          <Link to="/app/login" onClick={() => {
            closeSideMenu();
            localStorage.clear()
          }} className="sidebar-link">
            <div className="subContent mb-2">
              <MdOutlinePowerSettingsNew className='icon' />
              <p className='actualText'>Log Out</p>
            </div>
          </Link>
      </div>}
      <div className='mt-auto text-center'>
          <p>Powered by <a href="https://impaxivesolutions.com" className='text-danger text-decoration-none fw-700' target='_blank' rel="noreferrer">Impaxive Solutions</a></p>
      </div>
    </div>
  )
}

export default Sidebar
