import React, { useEffect, useState } from 'react'
import "./manage-employees.css"
import { TextField } from '@mui/material'
// import Table from '../../components/table/Table'
import { Link } from 'react-router-dom'
import { getAllEmployees, deleteEmp } from '../../../../Api';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

function ManageAdminEmployees({navigation}) {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(2);
    const [totalPages, setTotalPages] = useState(null);

    const filteredData = [...tableData].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
      setSearchQuery(event.target.value);
      setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            let newPage = currentPage;
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            let newPage = currentPage;
            setCurrentPage(currentPage - 1);
        }
    };


    const editPage = () => {
        navigate('/admin/edit-employee');
    }

    const deleteEmployee = (account_code, emp_code) => {
        Swal.fire({
          text:  `Are you sure you want to delete the account?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(async (res) => {
          if(res.isConfirmed){
            let apiRes = await deleteEmp(account_code, emp_code);
            console.log(apiRes);
            if(apiRes.status === "S"){
              Swal.fire({
                text:  `Employee Deleted`,
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
              }).then(() => {
                fetchData();
              });
            }
            if(apiRes.status === 'F' && apiRes.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiRes.status === 'F' && apiRes.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
          }
        }) 
    }

    const fetchData = async () => {
        let apiData = await getAllEmployees();
        console.log(apiData);
        apiData.status === "S" ?  setTableData(apiData.result_info) : setTableData([]);
        apiData.status === "S" ?  setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)) : setTotalPages(0);
        if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
        if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        }
    }

    useEffect(() => {
        (async () => {
            let apiData = await getAllEmployees();
            console.log(apiData);
            apiData.status === "S" ?  setTableData(apiData.result_info) : setTableData([]);
            apiData.status === "S" ?  setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)) : setTotalPages(0);
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        })();
    }, [])

    
    return (
        <div className='manageContainer'>
        <h5 className='manage-employee'>Manage Employees</h5>
            <div className='searchbar-div mb-4'>
                <div className='searchbar'>
                    <TextField id="standard-basic" label="Search" variant="standard" value={searchQuery} onChange={handleSearchChange} />
                </div>
            </div>
            <div className="card table-card">
                <div className="tableContainer admin-emp-table">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Phone #</th>
                            <th scope="col">Email</th>
                            <th scope="col">Employee Code</th>
                            <th scope="col">Account Code</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.emp_name}</td>
                                    <td>{row.phone}</td>
                                    <td>{row.email}</td>
                                    <td>{row.emp_code}</td>
                                    <td>{row.account_code}</td>
                                    <td>
                                    <button className="btn btn-outline-primary px-4 me-2" onClick={() => {
                                        localStorage.setItem('empId', row.id)
                                        editPage();
                                    }}>Edit</button>
                                    <button className="btn btn-outline-danger px-3" onClick={() => {deleteEmployee(row.account_code, row.emp_code)}}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> 
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                            <i className="fa-solid fa-chevron-left"></i>
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                            <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
                {/* <Table rows={empData} headerCells={headerCells} update={setEmpData} deleteRow={deleteEmployee} editRoute={editPage} /> */}
            </div>
        </div>
    )
}

export default ManageAdminEmployees