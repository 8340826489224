import React from 'react'
import { TextField } from "@mui/material"
import { createMember } from '../../../../Api';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

function CreateMember() {
    const navigate = useNavigate();
    const [content, setContent] = React.useState({
        name: "",
        mobile: "",
        email: "",
        designation: "",
        dob: "",
        address: "",
        city: "",
        state: "",
        country: "",
        account_code: "",
        account_name: "",
    })

    const submit = async () => {
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'}
        });
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if(!content.name){
            Swal.fire({
                text: 'Please provide Employee name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(content.email && !emalval.test(content.email)){
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else{
            let data = {...content};
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            data.account_code = userData.account_code;
            let apiData = await createMember({...data});
            Swal.fire({
                html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
                showConfirmButton: false,
                heightAuto: false,
                customClass: {popup: 'loader-swal'},
                timer: 10
            });
            if(apiData.status === "S"){
                Swal.fire({
                    text: 'Member created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/bni/manage-members')
                })
            }
            if(apiData.status === "E"){
                Swal.fire({
                    text: apiData.message,
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }
    
    return (
        <div className='employeeContainer'>
            <h5 className='create-employee'>Create Member</h5>
            <div className="card forms-card">
                <div className="row mb-3">
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Member Name" variant="outlined" autoComplete="off" required 
                            onChange={(ev) => {
                                setContent({...content, name: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Mobile Number" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                let num = ev.target.value.replace(/[^0-9+\- ]/g, "")
                                setContent({...content, mobile: num})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Email" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setContent({...content, email: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Business Name" variant="outlined" autoComplete="off"
                            onChange={(ev) => {
                                setContent({...content, business_name: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Classification" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setContent({...content, classification: ev.target.value})
                            }}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button className='btn btn-dark me-4' onClick={() => {submit()}}>Create</button>
                  <button className='btn btn-danger' onClick={() => {navigate('/bni/manage-members')}}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default CreateMember
