import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  generateBNIReport,
} from "../../../Api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function GenerateBNIReport() {
  const navigate = useNavigate();
  const [isStartOpen, setIsStartOpen] = React.useState(false);
  const [isEndOpen, setIsEndOpen] = React.useState(false);
  const [reportType, setReportType] = React.useState("member");
  const [showDownload, setShowDownload] = React.useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleChange = (event) => {
    setReportType(event.target.value);
    setShowDownload("");
  };

  const generateReport = async () => {
    setShowDownload("");
    Swal.fire({
      html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
      showConfirmButton: false,
      heightAuto: false,
      customClass: {popup: 'loader-swal'}
    });
    let userData = JSON.parse(localStorage.getItem("userData") || "{}");
    let data = {};
    data.account_code = userData.account_code;
    data.report_type = reportType;
    if (startDate) {
      data.start_date = startDate;
    } else {
      Swal.fire({
        text: "Select start date",
        showConfirmButton: false,
        icon: "warning",
        timer: 3000,
      });
      return;
    }
    if (endDate) {
      data.end_date = endDate;
    } else {
      Swal.fire({
        text: "Select end date",
        showConfirmButton: false,
        icon: "warning",
        timer: 3000,
      });
      return;
    }
    let apiData = await generateBNIReport(data);
    Swal.fire({
      html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
      showConfirmButton: false,
      heightAuto: false,
      customClass: {popup: 'loader-swal'},
      timer: 10
    });
    if (apiData.status === "S") {
      if (apiData.result_info) {
        setShowDownload(apiData.result_info);
      }
    }
    if (
      apiData.status === "F" &&
      apiData.message === "Unauthorized - Missing token"
    ) {
      Swal.fire({
        text: "Please login with your credentials",
        icon: "warning",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        localStorage.clear();
        navigate("/app/login");
      });
    }
    if (
      apiData.status === "F" &&
      apiData.message === "Unauthorized - Invalid token"
    ) {
      Swal.fire({
        text: "Please login with your credentials",
        icon: "warning",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        localStorage.clear();
        navigate("/app/login");
      });
    }
  };

  return (
    <div className="generateContainer">
      <h5 className="generate-report">Reports</h5>
      <div className="card forms-card">
        <div className="row">
          <div className="col-12 mb-4">
            <FormControl fullWidth>
              <InputLabel className="w-100" id="demo-simple-select-label">
                Report Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reportType}
                label="Report Type"
                onChange={handleChange}
              >
                <MenuItem value={"member"}>Members Report</MenuItem>
                <MenuItem value={"substitute"}>Substitutes Report</MenuItem>
                <MenuItem value={"visitor"}>Visitors Report</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 mb-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={isStartOpen}
                onClose={() => setIsStartOpen(false)}
                slotProps={{
                  textField: {
                    readonly: true,
                    onClick: () => setIsStartOpen(true)
                  }
                }}
                className="w-100"
                label="Start Date"
                onChange={(ev) => {
                  let date = ev?.$d;
                  setStartDate(dayjs(date).format("YYYY-MM-DD"));
                  setEndDate("");
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-lg-6 mb-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={isEndOpen}
                onClose={() => setIsEndOpen(false)}
                slotProps={{
                  textField: {
                    readonly: true,
                    onClick: () => setIsEndOpen(true)
                  }
                }}
                disabled={startDate === ""}
                minDate={startDate ? dayjs(startDate) : ''}
                value={endDate ? dayjs(endDate) : null}
                className="w-100"
                label="End Date"
                onChange={(ev) => {
                  let date = ev?.$d;
                  setEndDate(dayjs(date).format("YYYY-MM-DD"));
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="text-center mt-3">
          <button
            className="btn btn-dark"
            onClick={() => {
              generateReport();
            }}
          >
            Generate Report
          </button>
        </div>
      </div>
      {showDownload && (
        <div className="card table-card mt-4">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Report Type</th>
                <th scope="col">Download</th>
              </tr>
            </thead>
            <tbody className="text-End">
              <tr>
                <td style={{textTransform: 'capitalize'}}>{reportType} Report</td>
                <td>
                  <a
                    href={"https://attendlog.com/api/files" + showDownload}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadIcon />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default GenerateBNIReport;
