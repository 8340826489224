import React, { useEffect, useState } from 'react'
import "./attendence.css"
import { TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import { getAttendanceLog } from '../../Api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function AttendenceLog() {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null)
    const [searchQuery, setSearchQuery] = useState('');
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const filteredData = [...tableData].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
      setSearchQuery(event.target.value);
      setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            let newPage = currentPage;
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            let newPage = currentPage;
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let acc_code = userData.account_code;
            setUserInfo(userData);
            let apiData = await getAttendanceLog(acc_code);
            apiData.status === "S" ?  setTableData(apiData.result_info) : setTableData([]);
            apiData.status === "S" ?  setTotalPages(Math.ceil([...apiData.result_info].length / pageSize)) : setTotalPages(0);
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        })();
    }, [])


    
    return (
        <div className='manageContainer'>
        <h5 className='manage-employee'>Attendance Log</h5>
            <div className='searchbar-div mb-4'>
                <div className='searchbar'>
                    <TextField id="standard-basic" label="Search" variant="standard" value={searchQuery} onChange={handleSearchChange} />
                </div>
            </div>
            <div className="card table-card">
                <div className="tableContainer location-table">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Date</th>
                            {userInfo && userInfo.account_type === 'ams' && <th scope="col">Location Name</th>}
                            <th scope="col">Employee Name</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.date}</td>
                                    {userInfo && userInfo.account_type === 'ams' && <td>{row.location_name}</td>}
                                    <td>{row.emp_name}</td>
                                    <td>
                                        <button className="btn btn-outline-primary px-4" onClick={() => {
                                            localStorage.setItem("log_date", row.date);
                                            localStorage.setItem("emp_code", row.emp_code);
                                            navigate('/app/individuallog');
                                        }}>View</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> 
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                            <i className="fa-solid fa-chevron-left"></i>
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                            <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AttendenceLog
