import React, { useEffect } from 'react'
import { TextField } from "@mui/material"
import { getMember, updateMember} from '../../../../Api';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from "sweetalert2";

function EditMember() {
    const navigate = useNavigate();
    const {member_id} = useParams();
    const [content, setContent] = React.useState({
        name: "",
        mobile: "",
        email: "",
        designation: "",
        dob: "",
        address: "",
        city: "",
        state: "",
        country: "",
        account_code: "",
        account_name: "",
    })
    
    const updateMemberData = async () => {
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'}
        });
        let apiData = await updateMember({
            name: content.name,
            mobile: content.mobile,
            email: content.email,
            business_name: content.business_name,
            classification: content.classification,
        }, member_id);
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'},
            timer: 10
        });
        if (apiData.status === "S") {
            Swal.fire({
                text: 'Member updated successfully',
                showConfirmButton: false,
                icon: 'success',
                timer: 3000,
            }).then(() => {
                navigate('/bni/manage-members')
            })
        } else if (apiData.status === "E" && apiData.result_code === 404) {
            Swal.fire({
                text: 'Member not found',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            }).then(() => {
                navigate('/bni/manage-members')
            })
        } else if (apiData.status === 'F' && (apiData.message === "Unauthorized - Missing token" || apiData.message === "Unauthorized - Invalid token")) {
            Swal.fire({
                text: "Please login with your credentials",
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                localStorage.clear();
                navigate('/app/login');
            })
        } else {
            Swal.fire({
                text: JSON.stringify(apiData),
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
        }
    }

    useEffect(() => {
        (async () => {
            Swal.fire({
                html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
                showConfirmButton: false,
                heightAuto: false,
                customClass: {popup: 'loader-swal'}
            });
            let apiData = await getMember(member_id);
            console.log(apiData);
            if (apiData.status === "S") {
                setContent(apiData.result_info)
            }else {
                setContent({
                    name: "",
                    mobile: "",
                    email: "",
                })
            }
            Swal.fire({
                html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
                showConfirmButton: false,
                heightAuto: false,
                customClass: {popup: 'loader-swal'},
                timer: 10
            });
        })()
    }, [member_id]);
    
    return (
        <div className='employeeContainer'>
            <h5 className='create-employee'>Edit Member</h5>
            <div className="card forms-card">
                <div className="row mb-3">
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Member Name" variant="outlined" autoComplete="off" required 
                            value={content.name || ""}
                            onChange={(ev) => {
                                setContent({...content, name: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Mobile Number" variant="outlined" autoComplete="off" 
                            value={content.mobile || ""}
                            onChange={(ev) => {
                                let num = ev.target.value.replace(/[^0-9+\- ]/g, "")
                                setContent({...content, mobile: num})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Email" variant="outlined" autoComplete="off" 
                            value={content.email || ""}
                            onChange={(ev) => {
                                setContent({...content, email: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Business Name" variant="outlined" autoComplete="off"
                            value={content.business_name || ""}
                            onChange={(ev) => {
                                setContent({...content, business_name: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Classification" variant="outlined" autoComplete="off" 
                            value={content.classification || ""}
                            onChange={(ev) => {
                                setContent({...content, classification: ev.target.value})
                            }}
                        />
                    </div>
                </div>
                <div className="text-center">
                  <button className='btn btn-dark me-4'  
                //   onClick={() => {fetchData()}} 
                  onClick={updateMemberData}
                  >Update</button>
                  <button className='btn btn-danger' onClick={() => {navigate('/bni/manage-members')}}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default EditMember
