import React, {useState, useEffect} from 'react'
import "./individual.css"
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Accordion from '../../components/accordion/Accordion';
import { useNavigate } from 'react-router-dom';
import {getEmpLogByDate} from '../../Api';
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    textAlign: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

function IndividualLog() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [accType, setAccType] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const [empName, setEmpName] = useState('');
    const [dateEmp, setDateEmp] = useState('');
    const [empData, setEmpData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(null);

    const [modalImg, setModalImg] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const handleOpen = (url) => {
        setOpen(true);
        setModalImg(url)
    };
    const handleClose = () => setOpen(false);

    const filteredData = [...empData].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
      setSearchQuery(event.target.value);
      setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            let newPage = currentPage;
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            let newPage = currentPage;
            setCurrentPage(currentPage - 1);
        }
    };


    const editPage = () => {
        navigate('/pages/editemployee');
    }

    const fetchData = async () => {
        let userInfo = JSON.parse(localStorage.getItem('userData') || '{}');
        setAccType(userInfo.account_type);
        setUserData(userInfo);
        let data = {
            "account_code": userData.account_code,
            "date": localStorage.getItem('log_date'),
            "emp_code": localStorage.getItem('emp_code')
        }
        let apiData = await getEmpLogByDate(data);
        console.log(apiData);
        if(apiData.status === "S"){
            setEmpData(apiData.result_info);
            setEmpName(apiData.result_info[0].emp_name);
            setDateEmp(apiData.result_info[0].date);
            setTotalPages(Math.ceil([...apiData.result_info].length / pageSize));
        }else{
            setEmpData([]);
            setTotalPages(Math.ceil([...apiData.result_info].length / pageSize));
        }
    }

    useEffect(() => {
        (async () => {
            let userInfo = JSON.parse(localStorage.getItem('userData') || '{}');
            setAccType(userInfo.account_type);
            setUserData(userInfo);
            let data = {
                "account_code": userInfo.account_code,
                "date": localStorage.getItem('log_date'),
                "emp_code": localStorage.getItem('emp_code')
            }
            let apiData = await getEmpLogByDate(data);
            console.log(apiData);
            if(apiData.status === "S"){
                setEmpData(apiData.result_info);
                setEmpName(apiData.result_info[0]?.emp_name);
                setDateEmp(apiData.result_info[0]?.date);
                setTotalPages(Math.ceil([...apiData.result_info].length / pageSize));
            }else{
                setEmpData([]);
                setTotalPages(Math.ceil([...apiData.result_info].length / pageSize));
                if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                    Swal.fire({
                        text: "Please login with your credentials",
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        localStorage.clear();
                        navigate('/app/login');
                    })
                }
                if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                    Swal.fire({
                        text: "Please login with your credentials",
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 3000
                    }).then(() => {
                        localStorage.clear();
                        navigate('/app/login');
                    })
                }
            }
        })();
    }, [])

  return (
    <div className='individualContainer' style={{position: 'relative'}}>
        <h5 className='individual-log'>Individual Log</h5>
        <div className="card forms-card">
            <div className="row mb-4">
                <div className="col-12 col-lg-6">
                    <TextField disabled className='w-100' id="outlined-basic" value={empName} label="Name" variant="outlined" autoComplete="off" required />
                </div>
                <div className="col-12 col-lg-6">
                    <TextField disabled className='w-100' id="outlined-basic" value={dateEmp} label="Date" variant="outlined" autoComplete="off" required />
                </div>
            </div>
            {accType === "cms" && <div className='searchbar-div mb-4'>
                <div className='searchbar'>
                    <TextField id="standard-basic" label="Search" variant="standard" value={searchQuery} onChange={handleSearchChange} />
                </div>
            </div>}
            <div className="card table-card">
                <div className="tableContainer emp-table">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Location</th>
                            {userData.account_type !== 'ams' && <th scope="col">Checkin Time</th>}
                            {userData.account_type !== 'ams' && <th scope="col">Checkin Image</th>}
                            {userData.account_type === 'ams' && <th scope="col">Login Time</th>}
                            {userData.account_type === 'ams' && <th scope="col">Login Image</th>}
                            {userData.account_type === 'ams' && <th scope="col">Logout Time</th>}
                            {userData.account_type === 'ams' && <th scope="col">Logout Image</th>}
                        </tr>
                        </thead>
                        <tbody>
                            {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.location_name}</td>
                                    <td>{row.checkin_time}</td>
                                    <td>
                                        <img 
                                            onClick={() => {
                                                handleOpen('https://attendlog.com/api/files/emp_attendance_images/'+row.checkin_image)}
                                            } 
                                            style={{height: '60px'}} 
                                            src={'https://attendlog.com/api/files/emp_attendance_images/'+row.checkin_image} 
                                            alt=""
                                        />
                                    </td>
                                    {userData.account_type === 'ams' && <td>{row.checkout_time}</td>}
                                    {userData.account_type === 'ams' && <td>
                                        <img 
                                            onClick={() => {
                                                handleOpen('https://attendlog.com/api/files/emp_attendance_images/'+row.checkout_image)}
                                            } 
                                            style={{height: '60px'}} 
                                            src={'https://attendlog.com/api/files/emp_attendance_images/'+row.checkout_image} 
                                            alt=""
                                        />
                                    </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table> 
                    {paginatedData && (accType === 'cms') && <div className="d-flex justify-content-end align-items-center">
                        <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                            <i className="fa-solid fa-chevron-left"></i>
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                            <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>}
                </div>
            </div>
            <div className="mt-4 text-center">
               <button className="btn btn-dark px-4" onClick={() => {navigate('/app/attendencelog')}}>Back</button>
            </div>
            

        </div>
        <Modal sx={style}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <img style={{height: '50vh'}} src={modalImg} alt="" />
        </Modal>
    </div>
  )
}

export default IndividualLog
