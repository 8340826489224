import React, { useEffect, useRef, useState } from 'react';
import '../capturelogin/capturelogin.css';
import { useNavigate, useParams } from 'react-router-dom';
import { getEmployeesData, captureAttendance, getEmployeeAttendanceInfo, markLogin, markLogout } from '../../Api';
import { compressImg } from '../../ImageCompressor';
import Swal from 'sweetalert2';
import {TextField,Autocomplete} from '@mui/material';
import * as geolib from 'geolib';

const locations = [
  { id: 1, name: 'Location 1', latitude: 14.5329274, longitude: 79.883353 },
  { id: 1, name: 'Location 1', latitude: 14.4950752, longitude: 78.3420854 },
  { id: 2, name: 'Location 2', latitude: 234.567, longitude: 890.123 },
];

function CheckinDemo() {
  const navigate = useNavigate();
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [position1, setPosition1] = useState({ latitude: locations[0].latitude, longitude: locations[0].longitude });
  const [dist, setDist] = useState(0);

  useEffect(() => {
    (async () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((pos) => {
          setPosition({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          });
        });
      } else {
        console.log("Geolocation is not available in your browser.");
      }
    })();
  }, []);
  
  const getDistance = (pos) => {
    // const distance = await calculateDistance(position, position1);
    if(pos.latitude){
      setTimeout(() => {
        const distance = geolib.getPreciseDistance(pos, position1);
        setDist(distance);
      }, 3000)
    }
  }

  useEffect(() => {
    getDistance(position);
  }, [position])

  

  // function calculateDistance(coord1, coord2) {
  //   const epsilon = 1e-6; // Small threshold for considering coordinates as equal
  //   if (Math.abs(coord1.latitude - coord2.latitude) < epsilon &&
  //       Math.abs(coord1.longitude - coord2.longitude) < epsilon) {
  //     return 0;
  //   }

  //   const R = 6371e3; // Radius of the Earth in meters
  //   const lat1Rad = toRadians(coord1.latitude);
  //   const lat2Rad = toRadians(coord2.latitude);
  //   const deltaLat = toRadians(coord2.latitude - coord1.latitude);
  //   const deltaLon = toRadians(coord2.longitude - coord1.longitude);
  
  //   const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
  //             Math.cos(lat1Rad) * Math.cos(lat2Rad) *
  //             Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
  
  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
  //   const distance = R * c; // Distance in meters
  
  //   return distance;
  // }

  // function toRadians(degrees) {
  //   return degrees * (Math.PI / 180);
  // }

  return (
    <> 
    {<div className="checkin-page">
      <div className="header">
        <div className="admin-name">
          Raven Corp
        </div>
      </div>
      <div className="divider"></div>
      {
        <div className="body1">
          {/* <img src={accInfo.account_logo ? 'https://attendlog.com/api/files/'+accInfo.account_logo : 'https://placehold.co/250x70'} alt="" /> */}
          User <br />
          {position.latitude + ', ' + position.longitude}
          <br />
          {dist}
          <br />
          Place <br />
          {position1.latitude + ', ' + position1.longitude}
          <button className="btn btn-dark mt-3 rounded-pill px-4" disabled={dist > 1} id="btnIp" onClick={() => {
            // if(accInfo.account_type === "ams"){
            //   fetchAttendanceLog(value?.emp_code);
            // }else{
            //   fileIp.current.click();
            // }
          }}>Mark Attendance</button>
        </div>
      }
      {/* { isThank &&
        <div className="body">
          <img src={accInfo.account_logo ? 'https://attendlog.com/api/files/'+accInfo.account_logo : 'https://placehold.co/250x70'} alt="" />
          <div className='w-100 text-center'>
            <i className="fa-regular fa-check-circle text-success"></i>
            {accInfo.account_type === 'cms' && <p className="mt-3 mb-4">Your checkin is successful</p>}
            {accInfo.account_type === 'ams' && <p className="mt-3 mb-4">{logType} is successful</p>}
          </div>
          <p>powered by <span className='text-warning'>AttendLog</span></p>
        </div>
      } */}
    </div>}
    </>
  )
}

export default CheckinDemo;