import React from 'react';
import './website.scss';

function PrivacyPolicy() {

  const [openMenu, setOpenMenu] = React.useState(false)

  return (
    <div className="website">
      <header>
          <nav className="navbar navbar-expand-lg navbar-fixed-top">
            <div className="container-fluid px-0">
              <a className="navbar-brand" href="./">
                <img src={require('../../assets/logo.png')} className="logo" alt="" />
              </a>
              <button className="navbar-toggler" onClick={() => {setOpenMenu(!openMenu)}} type="button" aria-controls="navbarNavDropdown" aria-expanded={openMenu}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={openMenu ? 'collapse navbar-collapse justify-content-lg-end align-items-lg-center show':'collapse navbar-collapse justify-content-lg-end'} id="navbarNavDropdown">
                <ul className="navbar-nav align-items-center">
                  <li className="header-menu" id="menus">
                    <a href="./#home">Home</a>
                  </li>
                  <li className="header-menu" id="menus">
                    <a href="./#how-it-works">How it works</a>
                  </li>
                  <li className="header-menu" id="menus">
                    <a href="./#benefits">Benefits</a>
                  </li>
                  {/* <li className="header-menu" id="menus">
                    <a href="#pricing">Pricing</a>
                  </li> */}
                  <li className="header-menu" id="menus">
                      <a href="./#contact_section"><button className="btn btn-large btn-demo rounded-pill px-4">FREE DEMO</button></a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
      </header>
      {/* Home Section */}
      <section className="banner-section d-flex align-items-center h-100 justify-content-center">
            <h3 className="banner-heading mb-0">Privacy Policy</h3>
      </section>
      {/* How it works */}
      <section className="privacy-section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <p>Thank you for using <span className='fw-700'>ATTENDLOG</span> offered by <span className='fw-700'>Impaxive Solutions</span> ("we", "us", or "our"). This Privacy Policy describes how your personal information is collected, used, and shared when you use our Service.</p>
                    <h4 className='privacy-heading fw-700 d-block'>Information We Collect</h4>
                    <p>When you use our service/product/software, we collect the following types of information:</p>
                    <p><span className='fw-700'>Personal Information:</span> When you register for an account, we collect certain personal information such as your name, email address, phone number, and company details.</p>
                    <p><span className='fw-700'>Attendance Data:</span> We collect attendance data when users mark their attendance using the QR and selfie-based system. This includes the time of attendance, location (if available), and the selfie image captured for verification.</p>
                    <p><span className='fw-700'>Device Information:</span> We collect information about the device you use to access the Service, including the device type, operating system, and unique device identifiers.</p>
                    <h4 className='privacy-heading fw-700 d-block'>How We Use Your Information</h4>
                    <p>We use the information we collect for the following purposes:</p>
                    <ul>
                        <li>To provide and maintain the Service;</li>
                        <li>To verify attendance and prevent fraudulent activities;</li>
                        <li>To communicate with you about the Service, including updates and announcements;</li>
                        <li>To personalize and improve the Service;</li>
                        <li>To comply with legal obligations.</li>
                    </ul>
                    <h4 className='privacy-heading fw-700 d-block'>Sharing Your Information</h4>
                    <p>We may share your personal information with third parties in the following circumstances:</p>
                    <ul>
                        <li>With service providers who help us operate the Service;</li>
                        <li>With your consent or at your direction;</li>
                        <li>To comply with legal requirements or protect our rights.</li>
                    </ul>
                    <h4 className='privacy-heading fw-700 d-block'>Data Retention</h4>
                    <p>We retain your personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
                    <h4 className='privacy-heading fw-700 d-block'>Security</h4>
                    <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

                    <h4 className='privacy-heading fw-700 d-block'>Changes to This Privacy Policy</h4>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                    <h4 className='privacy-heading fw-700 d-block'>Contact Us</h4>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:attendlog@impaxivesolutions.com" target='_blank' className="link-color" rel='noreferrer'>attendlog@impaxivesolutions.com</a></p>
                </div>
            </div>
        </div>
      </section>
      {/* Footer */}
      <footer className="bg-light" id="footer">
        <div className="container-fluid">
          <div className="row align-items-center">
              <div className="col-12 col-md-6 text-md-start text-center text-small p-lg-0 pb-sm-3">
                  <img src={require('../../assets/logo.png')} className="logo default" alt="Attendlog" />
              </div>
              <div className="col-12 col-md-6 text-md-end text-center text-large text-black pe-lg-0">
                  <div id="menu">
                      <p className="mb-0 text-black">Copyright &copy; 2024 <a className="link-color" href="https://www.impaxivesolutions.com/" target='_blank' rel='noreferrer'>Impaxive Solutions</a></p>
                  </div>
              </div>
          </div>
        </div>
      </footer>
      {/* <a className="scroll-top-arrow bg-orange scroll-top-home" href="javascript:void(0);"><i class="ti-arrow-up"></i></a> */}
    </div>
  )
}

export default PrivacyPolicy