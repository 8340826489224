import React, { useEffect } from 'react'
import "./createlocation.css"
import { TextField } from '@mui/material';
import { createLoc } from '../../Api';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function CreateLocation() {

    const navigate = useNavigate();
    const [userInfo, setUserInfo] = React.useState(null);
    const [name, setName] = React.useState('');
    const [code, setCode] = React.useState('');
    const [latitude, setLatitude] = React.useState(null);
    const [longitude, setLongitude] = React.useState(null);
    const [url, SetUrl] = React.useState('https://attendlog.com/ams/');

    const submit = async () => {
        if(!name){
            Swal.fire({
                text: 'Please provide location name',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
        }else if(!code){
            Swal.fire({
                text: 'Please provide location code',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
        }else if(userInfo.account_type !== 'ams' && !latitude){
            Swal.fire({
                text: 'Please provide latitide',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
        }else if(userInfo.account_type !== 'ams' && !longitude){
            Swal.fire({
                text: 'Please provide longitude',
                showConfirmButton: false,
                icon: 'warning',
                timer: 3000,
            })
        }else{
            let data = {
                location_name: name,
                location_code: code,
                latitude,
                longitude,
                url: url,
            };
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            data.account_code = userData.account_code;
            data.account_name = userData.business_name;
            let apiData = await createLoc({...data});
            console.log(apiData);
            if(apiData.status === "S"){
                Swal.fire({
                    text: 'Location created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-locations')
                })
            }
            if(apiData.result_code === 201){
                Swal.fire({
                    html: 'Limit Exceeded. <br> Please contact AttendLog team to upgrade your plan.',
                    // showConfirmButton: false,
                    confirmButtonColor: '#000',
                    icon: 'warning'
                }).then(() => {
                    navigate('/app/manage-locations')
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserInfo(userData);
        console.log(userData);
    }, [])

    return (
        <div className='locationContainer'>
            <h5 className='create-location'>Create Location(QR)</h5>
            <div className="card forms-card">
                <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' value={name} id="outlined-basic" label="Location Name" 
                            onChange={(ev) => {
                                setName(ev.target.value);
                            }} 
                            variant="outlined" 
                            autoComplete="off" 
                            required 
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' value={code}
                            onChange={(ev) => {
                                let regex = /[^a-z0-9_-]/gi;
                                let userData = JSON.parse(localStorage.getItem('userData') || '{}');
                                let val = ev.target.value.replace(regex, "")
                                setCode(val.toLowerCase());
                                SetUrl('https://attendlog.com/'+userData.account_type+'/'+userData.account_code+'/'+val.toLowerCase())
                            }} 
                            id="outlined-basic" label="Location Code" variant="outlined" autoComplete="off" required 
                        />
                    </div>
                    {userInfo && userInfo.account_type !== 'ams' && <>
                        <div className="col-12 col-lg-6 mb-3">
                            <TextField className='w-100' value={latitude} id="outlined-basic" label="Latitude" 
                                onChange={(ev) => {
                                    let regex = /[^0-9.]/gi;
                                    setLatitude(ev.target.value.replace(regex, ""));
                                }}
                                variant="outlined" 
                                autoComplete="off" 
                                required
                            />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <TextField className='w-100' value={longitude} id="outlined-basic" label="Longitude" 
                                onChange={(ev) => {
                                    let regex = /[^0-9.]/gi;
                                    setLongitude(ev.target.value.replace(regex, ""));
                                }} 
                                variant="outlined" 
                                autoComplete="off" 
                                required 
                            />
                        </div>
                    </>}
                    {/* <div className="col-12 mb-3">
                        <TextField className='w-100' value={url} disabled id="outlined-basic" label="Url" focused variant="outlined" autoComplete="off" />
                    </div> */}
                </div>
                <div className="row buttonsRow mt-4">
                    <button className='createButton me-4' onClick={() => {submit()}}>Create</button>
                    <button className='cancelButton' onClick={() => {navigate('/app/manage-locations')}}>Cancel</button>
                </div>

            </div>
        
        </div>
    )
}

export default CreateLocation
