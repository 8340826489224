import React from 'react'
import { TextField } from '@mui/material'

function Editlocation() {
  return (
    <div className='locationContainer'>
        <h5 className='create-location'>Edit Location(QR)</h5>
        <div className="card forms-card h-50">
            <div className="row mb-3">
                <div className="col-12 mb-4">
                    <TextField className='w-100' id="outlined-basic" label="Title" variant="outlined" autoComplete="off" required />
                </div>
                <div className="col-12 ">
                    <TextField className='w-100' id="outlined-basic" label="Location Code" variant="outlined" autoComplete="off" required />
                </div>
            </div>
            <div className="row buttonsRow mt-4">
               <button className='createButton me-4'>Update</button>
               <button className='cancelButton'>Cancel</button>
            </div>

        </div>
      
    </div>
  )
}

export default Editlocation
