import React, {useEffect} from 'react'
import {TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getAccount, updateAccount } from '../../../Api';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { compressImg } from '../../../ImageCompressor';

function BNIProfile() {
    const navigate = useNavigate();
    const fileIp = React.useRef(null);
    const [img, setImg] = React.useState('');
    const [imgFile, setImgFile] = React.useState(null);
    const [accountObj, setAccountObj] = React.useState({
        business_name: "",
        account_type: "",
        email: "",
        phone: "",
        notification_email: "",
        validity_start_date: "",
        validity_end_date: "",
        plan_name: "",
        logo: ""
    })

    const handleReset = () => {
        if(fileIp.current) {
            fileIp.current.value = "";
            fileIp.current.type = "text";
            fileIp.current.type = "file";
        }
    };

    const handleFile = (event) => {
        console.log(event);
        setImg('https://placehold.co/250x100');
        const file = event.target.files[0];
        console.log('Before compression', file.size)
        let splitfile = file.name;
        let extension = splitfile.split('.').pop();
        if(extension === 'png' || extension === 'PNG' || extension === 'jpg' || extension === 'JPG' || extension === 'jpeg' || extension === 'JPEG') {
            console.log('valid file');
            var reader = new FileReader();
            if(event.target.files[0]){
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e)=>{
                    setImg(e.target.result);
                }
                compressImg(event.target.files[0]).then(img => {
                    setImgFile(img);
                });
            }
        }else{
            Swal.fire({
                text: 'Invalid file format. Only .png, .jpg files are allowed',
                icon: 'warning',
                heightAuto: false
            })
            return
        }
    }

    const submit = async () => {
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'}
        });
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let account_code = userData.account_code;
        let formData = new FormData();
        if(imgFile){
            formData.append('logo', imgFile);
        }
        formData.append('notification_email', accountObj.notification_email);
        formData.append('account_code', account_code);
        let apiRes = await updateAccount(formData);
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'},
            timer: 10
        });
        if(apiRes.status === 'S'){
            Swal.fire({
                text: "Profile updated successfully",
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                window.location.reload()
            })
        }
    }

    useEffect(() => {
      (async () => {
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'}
        });
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let account_code = userData.account_code;
        let apiData = await getAccount(account_code);
        console.log(apiData);
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'},
            timer: 10
        });
        if(apiData.status === "S"){
          if(!apiData.result_info.logo){
              setImg('https://placehold.co/250x100');
          }
          setAccountObj({
            business_name: apiData.result_info.business_name,
            account_type: apiData.result_info.account_type,
            email: apiData.result_info.email,
            phone: apiData.result_info.phone,
            notification_email: apiData.result_info.notification_email,
            validity_start_date: apiData.result_info.validity_start_date,
            validity_end_date: apiData.result_info.validity_end_date,
            logo: apiData.result_info.logo
          });
        }else{
          setImg('https://placehold.co/250x100');
          if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
              Swal.fire({
                  text: "Please login with your credentials",
                  icon: 'warning',
                  showConfirmButton: false,
                  timer: 3000
              }).then(() => {
                  localStorage.clear();
                  navigate('/app/login');
              })
          }
          if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
              Swal.fire({
                  text: "Please login with your credentials",
                  icon: 'warning',
                  showConfirmButton: false,
                  timer: 3000
              }).then(() => {
                  localStorage.clear();
                  navigate('/app/login');
              })
          }
        }
      })();
    }, [navigate])
    
    return (
        <div className='employeeContainer'>
            <h5 className='create-employee'>Profile</h5>
            <div className="card forms-card">
                <div className="row mb-3 cust-field">
                    <div className="col-12 text-center">
                        {!imgFile && <img src={accountObj.logo ? 'https://attendlog.com/api/files/account_logos/'+accountObj.logo: img} alt="" style={{maxHeight: '100px', maxWidth: '100%'}} />}
                        {imgFile && <img src={img} alt="" style={{maxHeight: '100px', maxWidth: '100%'}} />}
                        <input type="file" accept=".png, .jpg, .jpeg, .webp" hidden ref={fileIp} onClick={handleReset} onChange={(ev) => {handleFile(ev)}} />
                        <br />
                        <button className="btn btn-dark px-4 mt-3 mb-4" onClick={() => {fileIp.current.click();}}>Change Image</button>
                    </div>
                    {accountObj.business_name && <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" disabled label="Account Name" variant="outlined" autoComplete="off" required 
                            value={accountObj.business_name || ''}
                            onChange={(ev) => {
                                setAccountObj({...accountObj, business_name: ev.target.value})
                            }}
                        />
                    </div>}
                    {accountObj.phone && <div className="col-12 col-lg-6 mb-3">
                        <TextField disabled className='w-100' id="outlined-basic" label="Mobile Number" variant="outlined" autoComplete="off" required 
                            value={accountObj.phone || ''}
                            onChange={(ev) => {
                                setAccountObj({...accountObj, phone: ev.target.value})
                            }}
                        />
                    </div>}
                    {accountObj.email && <div className="col-12 col-lg-6 mb-3">
                        <TextField disabled className='w-100' id="outlined-basic" label="Email" variant="outlined" autoComplete="off" required
                            value={accountObj.email || ''}
                            onChange={(ev) => {
                                setAccountObj({...accountObj, email: ev.target.value})
                            }}
                        />
                    </div>}
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Notification Email" variant="outlined" autoComplete="off" 
                            value={accountObj.notification_email || ''}
                            onChange={(ev) => {
                                setAccountObj({...accountObj, notification_email: ev.target.value})
                            }}
                        />
                    </div>
                    {accountObj.validity_start_date && <div className="col-12 col-lg-6 mb-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker disabled className='w-100' label="Validity Start Date*" 
                                value={dayjs(accountObj.validity_start_date) || ''}
                                onChange={(ev) => {
                                    let date = dayjs(ev.$d).format('YYYY-MM-DD');
                                    setAccountObj({...accountObj, validity_start_date: date})
                                }} 
                            />
                        </LocalizationProvider>
                    </div>}
                    {accountObj.validity_end_date && <div className="col-12 col-lg-6 mb-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker disabled className='w-100' label="Validity End Date*" 
                                value={dayjs(accountObj.validity_end_date) || ''}
                                onChange={(ev) => {
                                    let date = dayjs(ev.$d).format('YYYY-MM-DD');
                                    setAccountObj({...accountObj, validity_end_date: date})
                                }} 
                            />
                        </LocalizationProvider>
                    </div>}
                    <div className='text-center mt-3'>
                      <button className='btn btn-dark' onClick={() => {submit()}}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BNIProfile
