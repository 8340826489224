import { BrowserRouter, Route, Routes } from "react-router-dom";
import AttendenceLog from "./pages/attendencelog/AttendenceLog";
import CreateEmployee from "./pages/createemployee/CreateEmployee";
import CreateLocation from "./pages/createlocation/CreateLocation";
import GenerateReport from "./pages/generatereport/GenerateReport";
import IndividualLog from "./pages/individuallog/IndividualLog";
import Login from "./pages/login/Login";
import ManageEmployee from "./pages/manageemployee/ManageEmployee";
import ManageLocations from "./pages/managelocations/ManageLocations";
import Layout from "./pages/layout/Layout";
import Editemployee from "./pages/editemployee/Editemployee";
import Editlocation from "./pages/editlocation/Editlocation";
import Website from "./pages/website/Website";
import CaptureCMSLogin from "./pages/capturelogin/CaptureCMSLogin";
import CaptureLogin from "./pages/capturelogin/CaptureLogin";
import ManageAccounts from "./pages/admin/accounts/ManageAccounts/ManageAccounts";
import CreateAccount from "./pages/admin/accounts/CreateAccount/CreateAccount";
import EditAccount from "./pages/admin/accounts/EditAccount/EditAccount";
import Profile from "./pages/profile/Profile";
import ManageAdminEmployees from "./pages/admin/employees/ManageEmployees/ManageAdminEmployees";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import CheckinDemo from "./pages/checkindemo/CheckinDemo";
import PrivacyPolicy from "./pages/website/PrivacyPolicy";
import CreateMember from "./pages/bni/members/createmember/CreateMember";
import ManageMembers from "./pages/bni/members/managemembers/ManageMembers";
import EditMember from "./pages/bni/members/editmember/EditMember";
import CaptureCheckin from "./pages/bni/capture-checkin/CaptureCheckin";
import GenerateBNIReport from "./pages/bni/reports/Reports";
import BNIAttendanceLog from "./pages/bni/bniattendancelog/BNIAttendanceLog";
import ManageBNILocations from "./pages/bni/bnilocations/managebnilocations/ManageBNILocations";
import CreateBNILocation from "./pages/bni/bnilocations/createbnilocations/CreateBNILocations";
import BNIProfile from "./pages/bni/bniprofile/BNIProfile";

function App() {

  return (
    <>
      {/* <Login /> */}
         {/* <Navbar />
        <div className="components">
          <Sidebar /> */}
          {/* <CreateEmployee /> */}
          {/* <ManageEmployee /> */}
          {/* <CreateLocation /> */}
          {/* <ManageLocations /> */}
          {/* <AttendenceLog /> */}
          {/* <IndividualLog /> */}
          {/* <GenerateReport /> */}
        {/* </div> */}
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Website />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cms/:account_code/:location_code" element={<CaptureCMSLogin />} />
            <Route path="/ams/:account_code/:location_code" element={<CaptureLogin />} />
            <Route path="/amsdemo" element={<CheckinDemo />} />
            <Route path="/app/login" element={<Login />} />
            <Route path="/app/change-password" element={<ChangePassword />} />
            <Route path="/app/forgot-password" element={<ForgotPassword />} />
            <Route path="/app" element={<Layout />}>
              <Route index path="/app/create-employee" element={<CreateEmployee />} />
              <Route path="/app/manage-employees" element={<ManageEmployee />} />
              <Route path="/app/edit-employee" element={<Editemployee />} />
              <Route path="/app/create-location" element={<CreateLocation />} />
              <Route path="/app/manage-locations" element={<ManageLocations />} />
              <Route path="/app/attendencelog" element={<AttendenceLog />} />
              <Route path="/app/individuallog" element={<IndividualLog />} />
              <Route path="/app/generatereport" element={<GenerateReport />} />
              <Route path="/app/editlocation" element={<Editlocation />} />
              <Route path="/app/profile" element={<Profile />} />
              <Route path="/app/reset-password" element={<ResetPassword />} />
            </Route>
            <Route path="/admin" element={<Layout />}>
              <Route index path="/admin/create-account" element={<CreateAccount />} />
              <Route index path="/admin/edit-account/:account_code" element={<EditAccount />} />
              <Route index path="/admin/manage-accounts" element={<ManageAccounts />} />
              <Route index path="/admin/manage-employees" element={<ManageAdminEmployees />} />
            </Route>
            <Route path="/bni/:account_code/:qr_name" element={<CaptureCheckin />} />
            <Route path="/bni" element={<Layout />}>
              <Route index path="/bni/profile" element={<BNIProfile />} />
              <Route index path="/bni/create-member" element={<CreateMember />} />
              <Route index path="/bni/edit-member/:member_id" element={<EditMember />} />
              <Route index path="/bni/manage-members" element={<ManageMembers />} />
              <Route index path="/bni/create-qr" element={<CreateBNILocation />} />
              <Route index path="/bni/manage-qr" element={<ManageBNILocations />} />
              <Route index path="/bni/generatereport" element={<GenerateBNIReport />} />
              <Route index path="/bni/attendancelog" element={<BNIAttendanceLog />} />
            </Route>
          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
