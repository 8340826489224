import React, { useState, useEffect, useRef } from "react";
import "./capturecheckin.css";
import Swal from "sweetalert2";
import { compressImg } from "../../../ImageCompressor";
import { useParams } from "react-router-dom";
import {
  captureMemberAttendance,
  captureSubstituteAttendance,
  captureVisitorAttendance,
  getMembersData,
} from "../../../Api";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

function CaptureCheckin() {
  const [img, setImg] = useState("");
  const [imgFile, setImgFile] = useState(null);
  const { account_code, qr_name } = useParams();
  const inputRef = useRef();
  const cameraButton = useRef();
  const [isThank, setIsThank] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [type, setType] = useState("");
  const [substituteName, setSubstituteName] = useState("");
  const [mobile, setMobile] = useState("");
  const [content, setContent] = useState(null);
  const [value, setValue] = useState({});

  const handleImage = (event) => {
    setImg("");
    const file = event.target.files[0];
    // console.log('Before compression', file.size)
    let splitfile = file.name;
    let extension = splitfile.split(".").pop();
    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      console.log("valid file");
      var reader = new FileReader();
      if (event.target.files[0]) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e) => {
          setImg(e.target.result);
        };
        compressImg(event.target.files[0]).then((img) => {
          setImgFile(img);
        });
      }
    } else {
      Swal.fire({
        text: "Invalid file format. Only .png, .jpg files are allowed",
        icon: "warning",
        heightAuto: false,
      });
      return;
    }
  };

  const resetForm = () => {
    setSubstituteName("");
    setMobile("");
    setImg("");
    setImgFile(null);
    setValue({});
    setRefresh(!refresh);
  };

  const checkCameraAccess = (fromType) => {
    if (inputRef && inputRef.current && fromType === "user") {
      inputRef.current.click();
    }
  };

  const submit = async () => {
    setIsLoader(true);
    let formData = new FormData();
    let apiRes;
    if (type === "Member") {
      formData.append("member_id", value.member_id);
      formData.append("member_name", value.name);
      formData.append("account_code", account_code);
    }
    if (type === "Substitute") {
      formData.append("member_id", value.member_id);
      formData.append("member_name", value.name);
      formData.append("substitute_name", substituteName);
      formData.append("account_code", account_code);
    }
    if (imgFile && type !== "Visitor") {
      formData.append("selfie", imgFile);
    }
    if (!imgFile && type !== "Visitor") {
      setIsLoader(false);
      return Swal.fire({
        text: "Please take a selfie",
        showConfirmButton: false,
        icon: "warning",
        heightAuto: false,
        timer: 2000,
      });
    }
    let visitoData;
    if (type === "Visitor") {
      visitoData = {
        member_id: value.member_id,
        mobile: mobile,
        visitor_name: substituteName,
        invited_by: value.name,
        account_code: account_code,
      };
    }
    if (type === "Member") {
      apiRes = await captureMemberAttendance(formData);
    }
    if (type === "Substitute") {
      apiRes = await captureSubstituteAttendance(formData);
    }
    if (type === "Visitor") {
      apiRes = await captureVisitorAttendance(visitoData);
    }
    if (apiRes.status === "S") {
      setIsThank(true);
    } else if (apiRes.status === "F" && apiRes.result_code === 400) {
      setIsLoader(false);
      Swal.fire({
        text: "Already Checkedin",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 2000,
      });
    } else {
      setIsLoader(false);
      Swal.fire({
        text: "Something went wrong",
        icon: "warning",
        showConfirmButton: false,
        heightAuto: false,
        timer: 2000,
      });
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 2000);
  };

  useEffect(() => {
    (async () => {
      let apiRes = await getMembersData(account_code, qr_name);
      if (apiRes.status === "S") {
        setContent({ ...apiRes, members: apiRes.result_info });
      }else if(apiRes.status === 'F' && apiRes.result_code === 404){
        Swal.fire({
          text: apiRes.message,
          icon: "warning",
          showConfirmButton: false,
          heightAuto: false
        });
      }
    })()
  }, [account_code, qr_name]);

  return (
    <>
      {isLoader && (
        <div className="bni checkin-page bg-danger">
          <div className="clock"></div>
        </div>
      )}
      {isThank && content && !isLoader && (
        <div className="bni checkin-page">
          <div className="bni-header">
            <div className={navigator.userAgent.match('CriOS') ? "admin-name pt-5 pb-2" : "admin-name"}>Success</div>
          </div>
          <div className="body">
            <img
              src={
                content.account_logo
                  ? "https://attendlog.com/api/files/account_logos/" + content.account_logo
                  : "https://placehold.co/250x70"
              }
              alt=""
            />
            <div className="w-100 text-center">
              <i className="fa-regular fa-check-circle text-success"></i>
              <p className="mt-3 mb-4">Done</p>
            </div>
            <p style={{ fontWeight: 600 }}>
              Powered by{" "}
              <a
                href="https://impaxivesolutions.com"
                target="_blank"
                rel="noreferrer"
                style={{ color: "red", textDecoration: "none" }}
              >
                Impaxive Solutions
              </a>
            </p>
          </div>
        </div>
      )}
      {!isLoader && !isThank && !img && (
        <div className="bni checkin-page">
          <input
            type="file"
            hidden
            ref={inputRef}
            onChange={(ev) => {
              handleImage(ev);
            }}
            id="selfie"
            name="selfie"
            accept="image/*"
            capture
          />
          <div className="bni-header" id="bni-header">
            <div className="admin-name">
              {content && !img ? content.account_name : "Member Info"}
            </div>
          </div>
          {!img && content && content.account_logo && (
            <div className="text-center p-3 mt-3">
              <img
                src={"https://attendlog.com/api/files/account_logos/" + content.account_logo}
                alt=""
              />
            </div>
          )}
          {!img && content && content.members && (
            <div className="w-100 px-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Select Type"
                  onChange={(ev) => {
                    resetForm();
                    setType(ev.target.value);
                  }}
                >
                  <MenuItem value={"Member"}>Member</MenuItem>
                  <MenuItem value={"Substitute"}>Substitute</MenuItem>
                  <MenuItem value={"Visitor"}>Visitor</MenuItem>
                </Select>
              </FormControl>
              <div className="mt-3 w-100">
                {(type === "Substitute" || type === "Visitor") && (
                  <TextField
                    className="mb-3 w-100"
                    label={
                      (type === "Visitor" ? "Visitor" : "Substitute") + " Name"
                    }
                    value={substituteName}
                    onChange={(ev) => {
                      setSubstituteName(ev.target.value);
                    }}
                  />
                )}
                {type === "Visitor" && (
                  <TextField
                    className="mb-3 w-100"
                    label="Mobile"
                    value={mobile}
                    onChange={(ev) => {
                      let num = ev.target.value.replace(/[^0-9+\- ]/g, "")
                      setMobile(num);
                    }}
                  />
                )}
                {content.members && (
                  <Autocomplete
                    key={"form-refresh-" + refresh}
                    className="w-100"
                    disablePortal
                    onInputChange={(event, inputVal) => {
                      if (!inputVal) {
                        setValue({});
                      }
                    }}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    id="combo-box-demo"
                    options={[...content.members]}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          type === "Visitor" ? "Invited By" : "Select Member"
                        }
                      />
                    )}
                  />
                )}
              </div>
              <div className="text-center">
                {type !== "Visitor" && (
                  <button
                    className="btn btn-dark mt-3 px-4"
                    disabled={!value?.member_id}
                    id="cameraButton"
                    ref={cameraButton}
                    onClick={() => {
                      checkCameraAccess("user");
                    }}
                  >
                    Continue
                  </button>
                )}
                {type === "Visitor" && (
                  <button
                    className="btn btn-dark mt-3 rounded-pill px-4"
                    id="btnIp"
                    onClick={() => {
                      submit();
                    }}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {!isLoader && !isThank && img && value && (
        <div className="bni checkin-page">
          <div className="bni-header" id="bni-header">
            <div className={navigator.userAgent.match('CriOS') ? "admin-name pt-5 pb-2" : "admin-name"}>
              {content && !img ? content.account_name : "Member Info"}
            </div>
          </div>
          <div className="w-100 text-center px-3">
            <img src={img} className="my-3" alt="" />
            <TextField
              className="mb-3 w-100"
              disabled
              label="Member Name"
              value={value.name || ''}
            />
            <TextField
              className="mb-3 w-100"
              disabled
              label="Mobile"
              value={value.mobile || ''}
            />
            {/* <TextField
              className="mb-3 w-100"
              disabled
              label="Email"
              value={value.email}
            /> */}
            <TextField
              className="mb-3 w-100"
              disabled
              label="Business Name"
              value={value.business_name || ''}
            />
            <button
              className="btn btn-dark mt-3 rounded-pill px-4"
              disabled={!value?.member_id}
              id="btnIp"
              onClick={() => {
                submit();
              }}
            >
              Mark Attendance
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CaptureCheckin;
