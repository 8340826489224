import React from 'react';
import './website.scss';

function Website() {

  const [openMenu, setOpenMenu] = React.useState(false)

  return (
    <div className="website">
      <header>
          <nav className="navbar navbar-expand-lg navbar-fixed-top">
            <div className="container-fluid px-0">
              <a className="navbar-brand" href="./">
                <img src={require('../../assets/logo.png')} className="logo" alt="" />
              </a>
              <button className="navbar-toggler" onClick={() => {setOpenMenu(!openMenu)}} type="button" aria-controls="navbarNavDropdown" aria-expanded={openMenu}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={openMenu ? 'collapse navbar-collapse justify-content-lg-end align-items-lg-center show':'collapse navbar-collapse justify-content-lg-end'} id="navbarNavDropdown">
                <ul className="navbar-nav align-items-center">
                  <li className="header-menu" id="menus">
                    <a href="#home">Home</a>
                  </li>
                  <li className="header-menu" id="menus">
                    <a href="#how-it-works">How it works</a>
                  </li>
                  <li className="header-menu" id="menus">
                    <a href="#benefits">Benefits</a>
                  </li>
                  {/* <li className="header-menu" id="menus">
                    <a href="#pricing">Pricing</a>
                  </li> */}
                  <li className="header-menu" id="menus">
                      <a href="#contact_section"><button className="btn btn-large btn-demo rounded-pill px-4">FREE DEMO</button></a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
      </header>
      {/* Home Section */}
      <section className="section-padding" id="home">
        <div className="container-fluid wow fadeIn"> 
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6 text-black">
                <h3 className="banner-heading">New-Age Attendance <br className="d-none d-lg-block" /> Software with no hardware</h3>
                <p className="banner-sub-heading">A QR & Selfie based attendance software that enhances your organization’s productivity by effectively monitoring the employee attendance</p>
                <ul className="list-style-12">
                    <li className="alt-font text-medium"><i className="fa-solid fa-check me-2"></i>Increase Productivity</li>
                    <li className="alt-font text-medium"><i className="fa-solid fa-check me-2"></i>Acurate Payroll Inputs</li>
                    <li className="alt-font text-medium"><i className="fa-solid fa-check me-2"></i>Digital Transformation</li>
                </ul>
                <a href="#contact_section" className="btn btn-large btn-demo rounded-pill wow fadeInUp" data-wow-delay="0.2s">
                    Book Free demo
                </a>
            </div>
            <div className="col-12 col-lg-6 text-center mt-4 mt-lg-0 wow fadeInRight" data-wow-delay=".2s">
                <img className="home_banner_image" src={require('../../assets/ams-2.png')} alt="Banner_Image" />
            </div>
          </div>
        </div>
      </section>
      {/* How it works */}
      <section className="section-padding" id="how-it-works">
        <div className="container-fluid">
            <div className="col-12 text-center">
                <h5 className="section-heading">How it works?</h5>
            </div>
            <div className="row align-items-center">
                <div className="col-12 col-lg col-md-6 text-center feature-box-11">
                    <div className="d-inline-block text-center">
                        <img src={require('../../assets/ams-3.png')} className="step-image" alt="" />
                    </div>
                    <div className="section-obj-heading">Scan QR code at office location</div>
                </div>
                <div className="progress-line d-none d-lg-block"></div>
                <div className="col-12 col-lg col-md-6 text-center feature-box-11" data-wow-delay="0.2s">
                    <div className="d-inline-block text-center">
                        <img src={require('../../assets/ams-1.png')} className="step-image" alt="" />
                    </div>
                    <div className="section-obj-heading">Take Selfie from your mobile phone</div>
                </div>
                <div className="progress-line d-none d-lg-block"></div>
                <div className="col-12 col-lg col-md-6 text-center feature-box-11" data-wow-delay="0.5s">
                    <div className="d-inline-block text-center">
                        <img src={require('../../assets/ams-2.png')} className="step-image" alt="" />
                    </div>
                    <div className="section-obj-heading">Mark Attendance - Login/Logout</div>
                </div>
            </div>
        </div>
      </section>
      {/* CTA */}
      <section id="cta">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-lg-8 text-center">
                    <div className="position-relative overflow-hidden w-100">
                        <h5 className=" text-white">
                            Schedule a Free Demo
                        </h5>
                        <h4 className="text-white width-80 mx-auto lg-width-100 wow fadeInUp">
                            We will show our software live and explain how it can increase your employees productivity
                        </h4>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-12 text-center">
                    <a href="#contact_section" className="btn btn-large btn-demo rounded-pill wow fadeInUp" data-wow-delay="0.2s">
                        Book Free demo
                        <i className="fas fa-arrow-right ms-2"></i>
                    </a>
                </div>
            </div>
        </div>
      </section>
      {/* Features Section */}
      <section className="section-padding" id="benefits">
        <div className="container-fluid wow fadeIn" data-wow-delay="0.4s"> 
            <div className="col-12 text-center">
                <h5 className="section-heading">Benefits</h5>
            </div>
            <div className="row">
                <div className="col-12 col-lg-4 col-md-6 last-paragraph-no-margin margin-30px-bottom wow fadeInUp" data-wow-delay=".2s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card">
                        <div className="bg-choose">
                          <i className="fa-solid fa-hand-holding-dollar"></i>
                        </div>
                        <span className="text-large">One-Time Cost</span>
                    </div>
                </div>
                 <div className="col-12 col-lg-4 col-md-6" data-wow-delay=".5s">
                    <div className="padding-50px-all lg-padding-40px-all sm-padding-30px-all bg-white box-shadow text-center feature-card">
                        <div className="bg-choose">
                          <i className="fa-solid fa-camera-retro"></i>
                        </div>
                        <span className="text-large">No hardware device needed</span>
                    </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6">
                    <div className="text-center feature-card">
                        <div className="bg-choose"><i className="fa-solid fa-map-location-dot"></i></div>
                        <span className="text-large">QR & Selfie based attendance</span>
                    </div>                    
                </div>
                <div className="col-12 col-lg-4 col-md-6">
                    <div className="text-center feature-card">
                        <div className="bg-choose">
                          <i className="fa-solid fa-arrows-down-to-people"></i>
                        </div>
                        <span className="text-large">Supports Multiple Branches</span>
                    </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6">
                    <div className="text-center feature-card">
                        <div className="bg-choose"><i className="fa-solid fa-people-line"></i></div>
                        <span className="text-large">User friendly reports</span>
                    </div>                    
                </div>
                <div className="col-12 col-lg-4 col-md-6">
                    <div className="text-center feature-card">
                        <div className="bg-choose"><i className="fa-solid fa-paste"></i></div>
                        <span className="text-large">Captures latitude & longitude</span>
                    </div>                    
                </div>
            </div>
        </div>
      </section>
      {/* Pricing */}
      {/* <section className="section-padding" id="pricing">
        <div className="container-fluid px-0">
            <h5 className="section-heading text-center">Pricing</h5>
            <div className="d-flex align-items-center justify-content-center">
                <div className="row pricing-box-style1 w-100">
                    <div className="col-12 col-md-4 mb-3 mb-lg-0 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="card pricing-rates pricing-rates_blue shadow rounded text-left border-0">
                            <div className="card-body py-5">
                                <h6 className="text-uppercase mb-2">Starter</h6>
                                <div className="d-flex mb-1">
                                    <span className="price h5 mb-0 text-black">12000 INR (one time)</span>
                                </div>
                                <div className="pricing_list">                                                                
                                    <ul className="list-style-12 mb-0 ps-0">
                                        <li className="h6 text-muted mb-0">Up to 25 users</li>
                                    </ul>
                                </div>
                                <div className="text-center mt-4">
                                    <a href="#contact_section" className="btn btn-black">Talk to us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3 mb-lg-0 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="card pricing-rates  pricing-rates_yellow shadow rounded text-left border-0">
                            <div className="card-body py-5">
                                <h6 className="text-uppercase mb-2">Advanced</h6>
                                <div className="d-flex mb-1">
                                    <span className="price h5 mb-0 text-black">12000 INR + 400 Per employee (one time)</span>
                                </div>
                                <div className="pricing_list">
                                    <ul className="list-style-12 mb-0 ps-0">
                                        <li className="h6 text-muted mb-0">26 to 50 users</li>
                                    </ul>
                                </div>
                                <div className="text-center mt-4">
                                    <a href="#contact_section" className="btn btn-black">Talk to us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3 mb-lg-0 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="card pricing-rates pricing-rates_green shadow rounded text-left border-0">
                            <div className="card-body py-5">
                                <h6 className="text-uppercase mb-2">Pro</h6>
                                <div className="d-flex mb-1">
                                    <span className="price h5 mb-0 text-black">Custom</span>
                                </div>
                                <div className="pricing_list">
                                    <ul className="list-style-12 mb-0 ps-0">
                                        <li className="h6 text-muted mb-0">50+ users</li>
                                    </ul>
                                </div>
                                <div className="text-center mt-4">
                                    <a href="#contact_section" className="btn btn-black">Talk to us</a>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section> */}

      <section className="padding-50px-all sm-padding-30px-all bg-extra-dark-gray" id="contact_section">
        <div className="container-fluid p-0">
            <h5 className="section-heading text-white text-center">Contact Us</h5>
            <form id="contact-form1" name="form-contact" className="col-12 p-0 mx-auto">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 text-center">
                        <input type="text" name="Name" id="name1" placeholder="Name*" className="input-border-bottom" autoComplete="off" />
                        <input type="text" name="Email" id="email1" placeholder="E-mail*" className="input-border-bottom" autoComplete="off" />
                        <input type="text" name="Phone" id="phone1" placeholder="Phone Number*" className="input-border-bottom" autoComplete="off" />
                        <textarea name="Message" id="message" placeholder="Message" className="input-border-bottom"></textarea>
                        <button id="contact-us-button1" type="submit" className="btn btn-large btn-demo rounded-pill mt-3">send message</button>
                    </div>
                </div>
            </form>
        </div>     
      </section>
      <footer className="bg-light" id="footer">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-lg-3 text-lg-start text-center text-small ps-lg-0 pb-3 pb-lg-0">
                  <img src={require('../../assets/logo.png')} className="logo default" alt="Attendlog" />
              </div>
              <div className="col-12 col-lg-6 text-center text-small p-lg-0 pb-sm-3">
                <div id="menu">
                    <p className="mb-0 text-black">Copyright &copy; 2024 <a className="link-color" href="https://www.impaxivesolutions.com/" target='_blank' rel='noreferrer'>Impaxive Solutions</a></p>
                </div>
              </div>
              <div className="col-12 col-lg-3 text-lg-end text-center text-large text-black pe-lg-0">
                  <div id="menu">
                      <p className="mb-0 text-black"><a className="link-color" href="./privacy-policy">Privacy Policy</a></p>
                  </div>
              </div>
          </div>
        </div>
      </footer>
      {/* <a className="scroll-top-arrow bg-orange scroll-top-home" href="javascript:void(0);"><i class="ti-arrow-up"></i></a> */}
    </div>
  )
}

export default Website